
import { Options, Vue } from "vue-class-component";
import BrandHours from "./SubDiv/BrandHours.vue";
import MediaNews from "./SubDiv/MediaNews.vue";

@Options({
  components: {
    BrandHours,
    MediaNews,
  },
  data() {
    return {};
  },
})
export default class Media extends Vue {}
