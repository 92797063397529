
import { Options, Vue } from "vue-class-component";
import BoxWithBtn from "./BoxWithBtn.vue";

@Options({
  components: {
    BoxWithBtn,
  },
  props: {
    des_bold: Boolean,
    item: Map,
  },
})
export default class BoxWithDetail extends Vue {}
