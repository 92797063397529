
import { Options, Vue } from "vue-class-component";
import { kMobileNewsInfo } from "@/config/newsData";
import BoxWithLatestNews from "@/components/PC/BoxWithLatestNews.vue";

@Options({
  components: {
    BoxWithLatestNews,
  },
  data() {
    return {
      page: 0,
      info: kMobileNewsInfo.mtxw,
    };
  },
  methods: {
    onClickSwith(index: number) {
      if (this.selectedYear !== index) {
        this.selectedYear = index;
      }
    },
  },
})
export default class MediaNews extends Vue {}
