import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "news_unit_container" }
const _hoisted_2 = { id: "new_unit_title" }
const _hoisted_3 = {
  key: 0,
  id: "new_unit_des"
}
const _hoisted_4 = { id: "new_swith_year_container" }
const _hoisted_5 = ["onClick", "id"]
const _hoisted_6 = { id: "news_details_content2" }
const _hoisted_7 = { id: "news_details_subtitle" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  id: "news_details_des"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxWithDetail = _resolveComponent("BoxWithDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.info.title), 1),
    (_ctx.info.des)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.info.des), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.children, (item, index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: `year_${index}`,
          onClick: ($event: any) => (_ctx.onClickSwith(index, _ctx.year)),
          id: 
          _ctx.selectedYear == index
            ? 'new_selected_year_text'
            : 'new_selected_year_normal'
        
        }, _toDisplayString(item.year), 9, _hoisted_5))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.children[_ctx.selectedYear].children, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `box_${index}`,
          id: "news_details_content_box_row"
        }, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(item.subtitle), 1),
          (item.type == 'img')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("img", {
                  id: "news_details_img_without_magin",
                  src: item.img
                }, null, 8, _hoisted_9),
                (item.time)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(item.time), 1))
                  : _createCommentVNode("", true)
              ]))
            : (item.type == 'video')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("iframe", {
                    id: "news_details_iframe_without_magin",
                    frameborder: "0",
                    allow: "autoplay; fullscreen",
                    allowfullscreen: "true",
                    src: item.videoUrl
                  }, null, 8, _hoisted_12)
                ]))
              : (item.type == 'detailBox')
                ? (_openBlock(), _createBlock(_component_BoxWithDetail, {
                    key: 2,
                    item: item,
                    class: "detail_bg_box"
                  }, null, 8, ["item"]))
                : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}