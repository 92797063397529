import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "new_unit_title",
  id: "new_unit_title"
}
const _hoisted_2 = { id: "news_details_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxWithLatestNews = _resolveComponent("BoxWithLatestNews")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.info.title), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.news, (item, index) => {
        return (_openBlock(), _createBlock(_component_BoxWithLatestNews, {
          key: index,
          item: item
        }, null, 8, ["item"]))
      }), 128))
    ])
  ], 64))
}